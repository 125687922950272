import jQuery from 'jquery'
import Flickity from 'flickity'
import 'flickity-imagesloaded'

import 'flickity/css/flickity.css'

(function ($) {

	const CELL_SELECTOR = ".box-4:not(.cell-hidden)"

	/**
	 * Add resize class to Flickity for even-height flickity items
	 */
	Flickity.prototype._createResizeClass = function () {
		this.element.classList.add('flickity-resize')
	}

	Flickity.createMethods.push('_createResizeClass')

	var resize = Flickity.prototype.resize

	Flickity.prototype.resize = function () {
		this.element.classList.remove('flickity-resize')
		resize.call(this)
		this.element.classList.add('flickity-resize')
	}

	function getSliderCellsLength(element) {
		const cells = Array.from(element.querySelectorAll(CELL_SELECTOR))
		return cells.reduce((total, cell) => total + cell.offsetWidth, 1)
	}

	function flickitySlider(element) {
		var isWiderThanViewport = getSliderCellsLength(element) >= $(window).width()

		new Flickity($(element)[0], {
			contain: true,
			pageDots: false,
			imagesLoaded: true,
			cellSelector: CELL_SELECTOR,
			wrapAround: isWiderThanViewport,
			prevNextButtons: isWiderThanViewport,
			draggable: isWiderThanViewport
		})
	}

	$('[data-slider]').each(function() {
		flickitySlider(this)
	})

	/**
	 * Handle filter groups.
	 * Filter groups expect the following markup:
	 *
	 * <div data-filter-group="filterGroupID">
	 * 	<button class="active" data-filter="*">All</button>
	 * 	<button data-filter="a">Filter A</button>
	 * </div>
	 *
	 * <div data-filter-target="filterGroupID">
	 * 	<div data-groups='["a"]'>Target A</div>
	 * 	<div data-groups='["a", "b"]'>Target AB</div>
	 * 	<div data-groups='["c"]'>Target C</div>
	 * </div>
	 */
	$('[data-filter-group]').on('click', '[data-filter]', function (event) {
		var filterGroup = $(event.delegateTarget).data('filterGroup')
		var filterValue = $(event.currentTarget).data('filter')

		var $filterGroup = $('[data-filter-group=' + filterGroup + ']')
		var $filterTarget = $('[data-filter-target=' + filterGroup + ']')
		var $filterElements = $filterTarget.find('[data-groups]')

		if (!$filterTarget.length) {
			console.log(`No filter target found for "${filterGroup}"`)
			return
		}

		if (!$filterElements.length) {
			console.log(`No filter elements found in "${filterGroup}" target`)
			return
		}

		$filterGroup.find('[data-filter]').removeClass('active')

		var $filterGroupFilter = $filterGroup.find(
			'[data-filter="' + CSS.escape(filterValue) + '"]'
		)
		if ($filterGroupFilter.length) {
			$filterGroupFilter.addClass('active')
		} else {
			$(event.currentTarget).addClass('active')
		}

		var flkty = Flickity.data($filterTarget[0])

		$filterElements.each(function () {
			var values = $(this).data('groups')

			if (filterValue === '*' || values.indexOf(filterValue) > -1) {
				if (flkty) {
					$(this).removeClass('cell-hidden')
				}
				$(this).fadeIn(450)
			} else {
				if (flkty) {
					$(this).addClass('cell-hidden')
					$(this).hide()
				} else {
					$(this).fadeOut(450)
				}
			}
		})

		if (flkty) {
			flkty.destroy()
			flickitySlider($filterTarget[0])
		}

		var currentTargetHref = $(event.currentTarget).attr('href')
		if (currentTargetHref && currentTargetHref.startsWith('#')) {
			event.preventDefault()
		}

		var offsetTop = $('.header').height() || 0
		var scrollTop = $(window).scrollTop() + offsetTop
		var windowHeight = $(window).height()
		var targetOffset = $filterTarget.offset().top
		var targetHeight = $filterTarget.height()

		var isBelow = targetOffset + (windowHeight / 4) >= scrollTop + windowHeight
		var isAbove = targetOffset + targetHeight <= scrollTop

		if (isBelow || isAbove) {
			$('html, body').stop().animate({
				scrollTop: targetOffset + (targetHeight / 2) - offsetTop - (windowHeight / 2)
			}, 300, 'swing')
		}
	})

})(jQuery)
