import imageMapResize from 'image-map-resizer'

const ImageMap = function () {

	function init() {

		const imgs = {}
		const maps = document.querySelectorAll('map')

		function handleMatchChange(name) {

			let firstMatch

			imgs[name].forEach(map => {
				map.removeAttribute('name')

				if (!firstMatch) {
					const query = map.dataset.media
					const mediaQuery = window.matchMedia(query)
					if (mediaQuery.matches) {
						firstMatch = map
						map.setAttribute('name', name)
						imageMapResize('map[name]')
					}
				}
			})
		}

		maps.forEach(map => {
			const query = map.dataset.media

			if (query) {
				const name = map.getAttribute('name')

				if (!imgs[name]) imgs[name] = []
				imgs[name].push(map)

				map.removeAttribute('name')

				const mediaQuery = window.matchMedia(query)
				if (mediaQuery.addEventListener) {
					mediaQuery.addEventListener('change', () => handleMatchChange(name))
				} else {
					mediaQuery.addListener(() => handleMatchChange(name))
				}

				handleMatchChange(name)
			}
		})
	}

	return {
		init,
	}
}()

export default ImageMap
