import Revealer, { withClassnames, withA11y, withClose } from './reveal';
import {
	debounce,
	unsetTransitionableHeight,
	setTransitionableHeight
} from './helpers'

const STATE_ACTIVE = 'is-active'
const STATE_OPEN = 'is-open'

class NavMenu extends withClassnames(withA11y(withClose(Revealer))) {

	static get defaults() {
		return Object.assign({}, this.constructor.defaults, {
			navItemSelector: 'li',
			navLevelSelector: 'ul',
		})
	}

	constructor(element, config) {
		super(element, Object.assign({}, NavMenu.defaults, config))
	}

	init() {
		super.init()

		// add a single event listener on the menu element
		this.element.addEventListener('click', event => {
			const sibling = event.target.nextElementSibling
			if (sibling && this.isMenuLevel.call(this, sibling)) {
				event.preventDefault()
				this.onMenuLevelClick(event.target.parentElement)
			} else {
				// just close the menu for anchor links
				const url = event.target.href && new URL(event.target.href)
				if (url && url.hash && url.pathname === location.pathname) {
					this.hide()
				}
			}
		})

		// add event listeners for back buttons
		this.getMenuLevels().forEach(element => {
			const level = element.querySelector(this.config.navLevelSelector)
			const button = level.querySelector('.navmenu-level-header button')
			button.addEventListener('click', event => {
				event.preventDefault()
				event.stopPropagation()

				this.closeMenuLevels(element)
			})
		})

		// Debounce resize handler
		this.handleResize = debounce(this.handleResize.bind(this), 250)

		window.addEventListener('resize', this.handleResize)
	}



	/**
	 * Gets all siblings of the given element
	 * @param  {HTMLElement}   element
	 * @return {HTMLElement[]}
	 */
	getSiblings(element) {
		return Array.from(element.parentElement.children).filter(child => child !== element)
	}



	/**
	 * Gets the topmost menu level (the menu itself)
	 * @return {HTMLElement}
	 */
	get menu() {
		return this.element.querySelector(this.config.navLevelSelector)
	}



	/**
	 * Gets an array of all parentElements matching the selector up until the topmost menu level
	 * @param {HTMLElement} element
	 * @param {string}      selector
	 * @returns {HTMLElement[]}
	 */
	getParentElements(element, selector = '*') {
		const elements = []

		while (!element.parentNode.contains(this.menu)) {
			element = element.parentNode

			if (element.matches(selector)) {
				elements.push(element)
			}
		}

		return elements
	}



	/**
	 * Test if the given element is a menu level
	 * @param  {HTMLElement} element
	 * @return {boolean}
	 */
	isMenuLevel(element) {
		return element.matches(this.config.navLevelSelector)
	}



	/**
	 * Returns an array of all menu levels inside the given element (including nested ones)
	 * @param  {HTMLElement}   element
	 * @return {HTMLElement[]}
	 */
	getMenuLevels(element = this.element) {
		const levels = []
		const selector = this.config.navLevelSelector

		element.querySelectorAll(selector).forEach(level => {
			const levelParent = level.closest(this.config.navItemSelector)
			if (levelParent) {
				levels.push(levelParent)
			}
		})

		return levels
	}



	/**
	 * Close all menu levels inside the given element
	 * @param {HTMLElement} element
	 * @param {boolean}     self    Close all menu levels including given level
	 */
	closeMenuLevels(element = this.element, self = false) {
		if (self && this.isMenuLevel.bind(this, element)) {
			callback(element)
		}

		this.getMenuLevels(element).forEach(callback)

		const parentMenuLevels = this.getParentElements(element, this.config.navLevelSelector)
		const totalMenuWidth = parentMenuLevels.reduce((total, element) => {
			return total += element.offsetWidth
		}, self ? element.offsetWidth : 0)

		this.element.style.setProperty('--menu-width', totalMenuWidth + 'px')

		function callback(element) {
			element.classList.remove(STATE_OPEN)
		}
	}



	/**
	 * @param  {HTMLElement} element
	 * @return {void}
	 */
	onMenuLevelClick(element) {
		// Close all sibling menus
		this.getSiblings(element).forEach(sibling => {
			this.closeMenuLevels(sibling, true)
		})

		// Open or close the current menu
		const submenuOpen = element.classList.toggle(STATE_OPEN)

		const submenu = element.querySelector(this.config.navLevelSelector)
		const submenuHeight = submenu.scrollHeight
		const parentElement = element.closest(this.config.navLevelSelector)

		if (submenuOpen) {
			// Make sure parent height is equal to or grater than submenu height
			if (submenuHeight > parentElement.offsetHeight) {
				setTransitionableHeight(parentElement, submenuHeight)
			}

			const totalMenuWidth = this.getParentElements(submenu, this.config.navLevelSelector).reduce((total, element) => {
				return total += element.offsetWidth
			}, submenu.offsetWidth)

			// Set the width of the submenu
			this.element.style.setProperty('--menu-width', totalMenuWidth + 'px')
		} else {
			// Close all submenus if menu current menu was closed
			this.closeMenuLevels(element)

			// Unset parent height if it has been set
			if (parentElement && parentElement.style.height) {
				unsetTransitionableHeight(parentElement)
			}
		}
	}



	beforeShow() {
		super.beforeShow(...arguments)

		// Make the menu start opened at the current place
		this.getMenuLevels().forEach(element => {
			if (element.classList.contains(STATE_ACTIVE)) {
				this.onMenuLevelClick(element)
			}
		})
	}



	beforeHide() {
		super.beforeHide(...arguments)

		// Close all submenus
		this.closeMenuLevels()
	}



	handleResize() {
		this.getMenuLevels().forEach(element => {
			if (element.classList.contains(STATE_OPEN)) {
				const submenu = element.querySelector(this.config.navLevelSelector)
				const totalMenuWidth = this.getParentElements(submenu, this.config.navLevelSelector).reduce((total, element) => {
					return total += element.offsetWidth
				}, submenu.offsetWidth)

				this.element.style.setProperty('--menu-width', totalMenuWidth + 'px')
			}
		})
	}


}

export default NavMenu
