import 'a11y-dialog'

import { disableBodyScrolling, enableBodyScrolling } from './helpers'

const Dialog = function () {

	function init() {
		const dialogs = document.querySelectorAll('[data-a11y-dialog]')

		dialogs.forEach(dialog => {
			dialog.addEventListener('show', disableBodyScrolling)
			dialog.addEventListener('hide', enableBodyScrolling)
		})
	}

	return {
		init,
	}
}()

export default Dialog
