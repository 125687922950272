import Cookies from 'js-cookie'

import Revealer, { withClassnames, withA11y, getEventPath } from './reveal'

/**
 * This script is currently written in a way that it works with
 * input[type="checkbox"] only, so we scope the selctor to that.
 */
const INPUT_SELECTOR = 'input[data-cookie-layer-option][type="checkbox"]'


class CookieLayer extends withClassnames(withA11y(Revealer)) {
	init() {
		this.inputs = Array.from(this.element.querySelectorAll(INPUT_SELECTOR))

		const cookies = this.decodeCookieOptions(Cookies.get('cookie-processed-02'))

		/**
		 * In case cookies are already processed, check if the saved options match
		 * the available options. If not, reset the cookies and show the layer.
		 */
		if (cookies) {
			const missing = this.inputs.filter(input => {
				return !Object.keys(cookies).includes(input.id)
			})
			const extra = Object.keys(cookies).filter(key => {
				return !this.inputs.find(input => input.id === key)
			})

			if (missing.length || extra.length) {
				this.resetCookies()
				this.config.hidden = false
			} else {
				this.config.hidden = true
			}
		} else {
			this.config.hidden = Boolean(cookies)
		}

		/**
		 * Prevent cookie layer from opening automatically if the element has
		 * the `data-cookie-layer-disable` attribute.
		 */
		if (this.element.hasAttribute('data-cookie-layer-disable')) {
			this.config.hidden = true
		}

		super.init()

		this.element.addEventListener('click', event => {
			const path = getEventPath(event)

			const acceptAllTrigger = this.element.querySelector('[data-cookie-layer-accept="all"]')
			const acceptNoneTrigger = this.element.querySelector('[data-cookie-layer-accept="none"]')
			const acceptSelectedTrigger = this.element.querySelector('[data-cookie-layer-accept="selected"]')

			// Check event.path for accept-all button
			if (acceptAllTrigger && path.includes(acceptAllTrigger)) {
				event.preventDefault()
				this.acceptAllCookies()
			}

			// Check event.path for accept-none button
			else if (acceptNoneTrigger && path.includes(acceptNoneTrigger)) {
				event.preventDefault()
				this.acceptNoCookies()
			}

			// Check event.path for accept-button
			else if (acceptSelectedTrigger && path.includes(acceptSelectedTrigger)) {
				event.preventDefault()
				this.acceptSelectedCookies()
			}
		})

	}

	beforeShow() {
		const cookies = this.decodeCookieOptions(Cookies.get('cookie-processed-02'))

		// Set inputs `checked` state to match cookies
		this.inputs.forEach(input => {
			if (cookies && cookies[input.id]) {
				input.checked = cookies[input.id]
			}
		})

		super.beforeShow()
	}

	/**
	 * Select all cookies (skipping disabled ones)
	 * @param {boolean} value The value to set all cookies to
	 */
	selectAllCookies(value) {
		this.inputs.forEach(input => {
			if (!input.disabled) {
				input.checked = Boolean(value)
			}
		})
	}

	/**
	 * Accept current selection
	 */
	acceptSelectedCookies() {
		this.setCookies()
		this.hide()
	}

	/**
	 * Accept all cookies
	 */
	acceptAllCookies() {
		this.selectAllCookies(true)

		// small delay to show that all cookies are accepted
		setTimeout(() => {
			this.acceptSelectedCookies()
		}, 150)
	}

	/**
	 * Accept no cookies
	 */
	acceptNoCookies() {
		this.selectAllCookies(false)

		// small delay to show that no cookies are accepted
		setTimeout(() => {
			this.acceptSelectedCookies()
		}, 150)
	}

	/**
	 * Encode cookie options
	 * @param {Object.<string, boolean>} obj
	 * @returns string
	 */
	encodeCookieOptions(obj) {
		return Object.keys(obj).map(key => `${key}:${obj[key]}`).join(',')
	}

	/**
	 * Decode cookie options
	 * @param {string} str
	 * @returns {Object.<string, boolean>|null}
	 */
	decodeCookieOptions(str) {
		return str ? str.split(',').reduce((obj, pair) => {
			const [key, value] = pair ? pair.split(':') : []
			obj[key] = value === 'true'
			return obj
		}, {}) : null
	}

	setCookies() {
		// Turn inputs into object
		const obj = this.inputs.reduce((acc, input) => {
			acc[input.id] = input.checked
			return acc
		}, {})

		const encoded = this.encodeCookieOptions(obj)

		// Set cookies
		Cookies.set('cookie-agreed', obj['ck_2'] ? 1 : 0, { expires: 30 })
		Cookies.set('cookie-processed-02', encoded, { expires: 30 })
	}

	resetCookies() {
		Cookies.remove('cookie-agreed')
		Cookies.remove('cookie-processed-02')
	}
}

export default CookieLayer
