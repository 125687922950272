import Global from './global'
import Dialog from './dialog'
import ImageMap from './imagemap'

// This needs to be rewritten
import './filtergroups'

/**
 * INIT
 *
 * This function initializes all modules used on the site, try to keep it as
 * simple as possible moving any complicated logic into the modules itself
 */
const Init = function () {
	Global.init()
	Dialog.init()
	ImageMap.init()

	window.DEHN = {
		Global,
		Dialog,
		ImageMap,
	}
}

// Initialize as soon as the DOM content has been loaded
document.addEventListener('DOMContentLoaded', Init)
