import 'focus-visible';
import { disableBodyScrolling, enableBodyScrolling } from './helpers';

import NavMenu from './nav-menu'
import Search from './search'
import CookieLayer from './cookie-layer';

const Header = function () {

	let elements, navMenu, search, cookieLayer

	function init() {
		elements = {
			nav: {
				menu: document.getElementById('page-nav-menu'),
				trigger: document.getElementById('menuButton'),
			},
			search: {
				element: document.getElementById('search-results-menu'),
				trigger: document.getElementById('meta-suche-link'),
			},
			cookieLayer: {
				element: document.getElementById('cookie-layer'),
				trigger: document.querySelectorAll('[data-cookie-layer-trigger]'),
			},
			grid: {
				element: document.getElementById('grid'),
				trigger: document.getElementById('grid-switch'),
			}
		}

		if (elements.nav.menu) {
			navMenu = new NavMenu(elements.nav.menu, {
				navLevelSelector: '.navmenu, .navmenu-level',
				trigger: elements.nav.trigger,
				await: 'transitionend',
				onBeforeShow: disableBodyScrolling,
				onBeforeHide: enableBodyScrolling,
			})
		}

		if (elements.search.element) {
			search = new Search(elements.search.element, {
				trigger: elements.search.trigger,
				await: 'transitionend',
				onBeforeShow: disableBodyScrolling,
				onBeforeHide: enableBodyScrolling,
			})
		}

		if (elements.cookieLayer.element) {
			cookieLayer = new CookieLayer(elements.cookieLayer.element, {
				trigger: elements.cookieLayer.trigger,
				await: 'transitionend',
				onBeforeShow: disableBodyScrolling,
				onBeforeHide: enableBodyScrolling,
			})
		}


		if (elements.grid.element && elements.grid.trigger) {
			elements.grid.trigger.addEventListener('click', () => {
				const style = window.getComputedStyle(elements.grid.element)
				const isHidden = style.getPropertyValue('display') === 'none'
				elements.grid.element.style.display = isHidden ? 'flex' : 'none'
			})
		}
	}

	return {
		init,
		get navMenu() {
			if (!navMenu) {
				console.warn('navMenu not initialized')
			}
			return {
				open: () => navMenu && navMenu.show(),
				close: () => navMenu && navMenu.hide(),
				get isOpen() {
					return navMenu ? !navMenu.hidden : false
				}
			}
		},
		get search() {
			if (!search) {
				console.warn('search not initialized')
			}
			return {
				open: () => search && search.show(),
				close: () => search && search.hide(),
				get isOpen() {
					return search ? !search.hidden : false
				}
			}
		},
		get cookieLayer() {
			if (!cookieLayer) {
				console.warn('cookieLayer not initialized')
			}
			return {
				open: () => cookieLayer && cookieLayer.show(),
				close: () => cookieLayer && cookieLayer.hide(),
				get isOpen() {
					return cookieLayer ? !cookieLayer.hidden : false
				}
			}
		},
	}
}()

export default Header
