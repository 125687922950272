export function throttle(func, waitTime) {
	let timeout = null
	let previous = 0

	const later = () => {
		previous = Date.now()
		timeout = null
		func()
	}

	return function() {
		const now = Date.now()
		const remaining = waitTime - (now - previous)
		if (remaining <= 0 || remaining > waitTime) {
			if (timeout) clearTimeout(timeout)
			later()
		} else if (!timeout) {
			timeout = setTimeout(later, remaining)
		}
	}
}

export function debounce(func, delay) {
	let inDebounce
	return function() {
		const context = this
		const args = arguments
		clearTimeout(inDebounce)
		inDebounce = setTimeout(() => func.apply(context, args), delay)
	}
}

/**
 * Remove inline styles from an element
 * @param {HTMLElement} element Element to remove the styles from
 * @param  {...string} props    Styles to remove
 */
export function removeStyle(element, ...props) {
	props.forEach(prop => {
		element.style[prop] = ''
		if (element.getAttribute('style') === '') {
			element.removeAttribute('style')
		}
	})
}

/**
 * Local reference to the last saved scroll position
 * @prop {number} x Horizontal scroll position
 * @prop {number} y Vertical scroll position
 */
const scrollPos = {
	x: window.pageXOffset,
	y: window.pageYOffset
}

/**
 * Disables scrolling on the whole page
 */
export function disableBodyScrolling() {
	if (isMobileSafari) {
		scrollPos.x = window.pageXOffset
		scrollPos.y = window.pageYOffset

		Object.assign(document.documentElement.style, {
			top:      `-${scrollPos.y}px`,
			left:     `-${scrollPos.x}px`,
			position: 'fixed',
			width:    '100vw',
			height:   '100vh',
			overflow: 'hidden'
		})
	} else {
		document.documentElement.style.overflow = 'hidden'
	}
}

/**
 * Enables scrolling again and restores scroll position
 */
export function enableBodyScrolling() {
	if (isMobileSafari) {
		removeStyle(document.documentElement, ...[
			'top',
			'left',
			'position',
			'width',
			'height',
			'overflow'
		])
		window.scrollTo(scrollPos.x, scrollPos.y)
	} else {
		removeStyle(document.documentElement, 'overflow')
	}
}


/**
 * Checks if the user agent identifies itself as mobile Safari
 * @returns {boolean}
 */
export const isMobileSafari = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;


/**
 * Sets the height in a way that allows CSS height transition to work
 * @param {HTMLElement}   element The element that should receive the height
 * @param {string|number} height  The height to set on the element
 */
export function setTransitionableHeight(element, height) {
	element.style.height = 'auto'
	element.style.transition = 'none'

	element.style.height = element.offsetHeight + 'px'
	element.style.transition = ''

	setTimeout(() => {
		element.style.height = isNaN ? height + 'px' : height
	}, 1)
}


/**
 * Unsets the height in a way that allows CSS height transition to work
 * @param {HTMLElement} element The element on wich the height should be removed
 */
export function unsetTransitionableHeight(element) {
	const initialHeight = element.style.height

	element.style.height = 'auto'
	element.style.transition = 'none'

	const height = element.offsetHeight

	element.style.height = initialHeight

	setTimeout(() => {
		element.style.transition = ''
		element.style.height = height + 'px'

		// Clean up after transition end
		element.addEventListener('transitionend', () => {
			removeStyle(element, 'height')
		}, { once: true })
	}, 1)
}

/**
 *
 * @param {HTMLElement} element
 */
export function removeChildren(element) {
	while (element.firstChild) {
		element.removeChild(element.firstChild)
	}
}
