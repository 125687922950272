import Revealer, { withClassnames, withA11y, withClose } from './reveal';
import { debounce, removeChildren } from './helpers'

class Search extends withClassnames(withA11y(withClose(Revealer))) {

	init() {
		super.init()

		// get elements
		this.form = this.element.querySelector('form')
		this.input = this.form.querySelector('input')
		this.results = {
			hierarchy: this.element.querySelector('#search-results-product'),
			content: this.element.querySelector('#search-results-content'),
		}

		this.form.addEventListener('submit', (e) => {
			e.preventDefault()
			this.search(this.input.value)
		})

		// handle keyboard navigation
		this.input.addEventListener('keydown', this.handleKeyboardNavigation.bind(this))

		// debounce the search
		this.input.addEventListener('keyup', debounce(() => {
			this.search(this.input.value)
		}, 500))
	}

	afterShow() {
		super.afterShow()
		this.input.focus()

		if (this.input.value) {
			this.input.select()
		}
	}

	// render the search results
	renderSearchResults = (element, results, hits = results.length, more = '') => {
		// hide if no results
		element.hidden = !hits

		const searchResults = element.querySelector('.table-links')
		removeChildren(searchResults)

		element.querySelector('h4 span').textContent = `(${hits})`
		element.querySelector('a.button').href = more

		results.forEach(result => {
			const el = document.createElement('a')
			el.href = result.url
			el.textContent = result.name

			searchResults.appendChild(el)
		})
	}

	// search for a term
	search = async (term) => {
		term = term.trim()

		if (term !== this.lastTerm && term.length > 0) {
			this.lastTerm = term

			// get url from from action attribute
			const url = this.form.getAttribute('action')

			const searchUrl = new URL(url)
			searchUrl.searchParams.set('mode', 'quick')
			searchUrl.searchParams.set('format', 'json')
			searchUrl.searchParams.set('limit', 4)
			searchUrl.searchParams.set('q', term)

			const results = await fetch(searchUrl).then(res => res.json())

			Object.keys(this.results).forEach(key => {
				const moreUrl = new URL(url)
				moreUrl.searchParams.set('origin', 'quicksearch')
				moreUrl.searchParams.set('q', term)
				moreUrl.searchParams.set('category', key)

				this.renderSearchResults(
					this.results[key],
					results.hits.filter(hit => hit.category === key),
					results[`${key}Hits`],
					moreUrl
				)
			})
		}
	}

	handleKeyboardNavigation(e) {
		if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
			e.preventDefault()

			const links = [...this.form.querySelectorAll('.table-links a')]
			const total = links.length - 1

			let index = links.indexOf(this.form.querySelector('.selected'))

			if (links[index]) {
				links[index].classList.remove('selected')
			}

			if (e.key === 'ArrowUp') {
				index = index > 0 ? index - 1 : total
			}
			if (e.key === 'ArrowDown') {
				index = index !== total ? index + 1 : 0
			}

			if (links[index]) {
				links[index].classList.add('selected')
			}
		}

		if (e.key === 'Enter') {
			e.preventDefault()
			this.form.querySelector('.selected').click()
		}
	}
}

export default Search
